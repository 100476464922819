<template>
  <div>
    <Navbar />
    <div class="container my-5">
      <div class="aboutPage">
        <div class="row">
          <div class="mt-3 pl-3 pr-3 about">
            <h2>{{ $t('about_the_project_page_title') }}</h2>
            <p class="text-left mt-3">
              {{ $t('about_the_project_page_text_par1') }}
            </p>
            <p class="text-left mt-3">
              {{ $t('about_the_project_page_text_par2') }}
            </p>
            <p class="text-left mt-3">
              {{ $t('about_the_project_page_text_par3') }}
            </p>
            <hr />
          </div>
          <div class="partners mt-3">
            <h2>{{ $t('partners_page_title') }}</h2>
            <div class="partner mt-4 pl-3 pr-3">
              <div class="view">
                <a href="https://naskr.kg/en/" target="_blank">
                  <img
                    class="img-fluid mx-auto"
                    :src="sciencesLogo"
                    alt="Sample image"
                  />
                </a>
              </div>
              <h5 class="mt-3">
                {{ $t('about_the_project_page_knaos_title') }}
              </h5>
              <p class="text-left">
                {{ $t('about_the_project_page_knaos_par1') }}
              </p>
              <hr />
            </div>
            <div class="partner mt-4 pl-3 pr-3">
              <div class="view">
                <a href="http://plateauperspectives.org/en/" target="_blank">
                  <img
                    class="img-fluid mx-auto logo2"
                    :src="plateauLogo"
                    alt="Sample image"
                  />
                </a>
              </div>
              <h5 class="mt-3">
                {{ $t('about_the_project_page_plateau_title') }}
              </h5>
              <p class="text-left">
                {{ $t('about_the_project_page_plateau_text') }}
              </p>
              <hr />
            </div>
            <div class="partner mt-4 pl-3 pr-3">
              <div class="view">
                <a
                  href="https://auca.kg/en/environmental_sustainability_climate_science/"
                  target="_blank"
                >
                  <img
                    class="img-fluid mx-auto logo3"
                    :src="escsLogo"
                    alt="Sample image"
                  />
                </a>
              </div>
              <h5 class="mt-3">
                {{ $t('about_the_project_page_auca_title') }}
              </h5>
              <p class="text-left">
                {{ $t('about_the_project_page_auca_text') }}
              </p>
              <hr />
            </div>
            <div class="partner mt-4 pl-3 pr-3">
              <div class="view">
                <a href="http://www.timelysoft.net/en/" target="_blank">
                  <img
                    class="img-fluid mx-auto"
                    :src="timelyLogo"
                    alt="Sample image"
                  />
                </a>
              </div>
              <h5 class="mt-3">
                {{ $t('about_the_project_page_timely_title') }}
              </h5>
              <p class="text-left">
                {{ $t('about_the_project_page_timely_text') }}
              </p>
              <hr />
            </div>
            <div class="partner mt-4 pl-3 pr-3">
              <h5 class="mt-3">
                {{ $t('about_the_project_page_sources_title') }}
              </h5>
              <p class="text-left">
                {{ $t('about_the_project_page_sources_text') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import SciencesLogo from '@/assets/partners/NationalAcademyOfSciencesLogo.png'
import escsLogo from '@/assets/partners/escs.png'
import PlateauLogo from '@/assets/partners/pp_logo.png'
import TimelySoftLogo from '@/assets/partners/logoTimelysoft.png'

export default {
  components: {
    Navbar
  },
  data() {
    return {
      sciencesLogo: SciencesLogo,
      escsLogo: escsLogo,
      plateauLogo: PlateauLogo,
      timelyLogo: TimelySoftLogo
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding-left: 0;
  padding-right: 0;
  .aboutPage {
    padding: 0 2rem 2rem 2rem;
    background: #ffffff;
    box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
    border-radius: 4px;
    .logo2 {
      width: 600px;
    }
    .logo3 {
      width: 250px;
      height: 250px;
    }
  }
}
.row {
  margin-right: 0;
  margin-left: 0;
}
</style>
